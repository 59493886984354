import React, {useEffect, useState} from "react";
import Products from "./Components/Products";
import Services from "./Components/Services";
import "./styles.scss";
import {RandomObjectMover} from "./Components/Wander";
import Footer from "./Components/Footer";
import Nav from "./Components/Nav";

const vh = parseInt(
	Math.max(
		document.documentElement.clientHeight || 0,
		window.innerHeight || 0
	)
);

export const NavBtn = ({setNavOpen, navOpen}) => {
	return (
		<nav>
			<div className="top">
				<a href="/">
					<img src={'https://assets-springhire.gumlet.io/logo.svg'}
						 data-src={'https://assets-springhire.gumlet.io/logo.svg'} alt={'SpringHire'}/>
				</a>
			</div>
			<div
				className={`bottom ${navOpen ? "open" : ""}`}
				onClick={() => {
					setNavOpen(!navOpen);
				}}
			>
				{[...Array(parseInt(3))].map((e, i) => (
					<span key={i}></span>
				))}
			</div>
		</nav>
	);
};

export default () => {
	const [navOpen, setNavOpen] = useState(false);

	useEffect(() => {
		const cursor = document.getElementsByClassName("window")[0];
		const hero = document.getElementsByClassName("hero")[0];
		var o = new RandomObjectMover(cursor, hero);
		var timer, timer1;

		o.start();

		hero.addEventListener(
			"mousemove",
			(e) => {
				timer && clearTimeout(timer);
				timer1 && clearTimeout(timer1);
				o.stop();
				let y = e.pageY + 200 < vh ? e.pageY - 200 : vh - 390,
					x = e.pageX - 200;
				cursor.setAttribute("style", `top: ${y}px; left: ${x}px;`);
				timer = setTimeout(() => {
					cursor.setAttribute("style", `top: 0; left: 0;`);
					cursor.style.transform = "translate3d(0, 0, 0)";
					timer1 = setTimeout(() => {
						o.start();
					}, 3000);
				}, 2000);
			},
			false
		);
	});

	return (
		<main className="home">
			<NavBtn setNavOpen={setNavOpen} navOpen={navOpen}/>
			<Nav navOpen={navOpen}/>
			<div className="hero">
				<div className="marquee">
					{[...Array(parseInt(vh / 20))].map((e, i) => (
						<span key={i}>
							{
								"< ANDRIOD > = < DEVELOPMENT > ; < USER-EXPERIENCE > ? < PRODUCT > # < MANAGEMENT > : < DESIGN > < RESEARCH > / < BRANDING > = < IDENTITY > ; < USER-INTERFACE > ? < DATA > # < CREATIVE > : < FRONT-END > $ < BACK-END > ! < STRATEGY > & < IOS > / < ANDRIOD > = < DEVELOPMENT > ; < USER-EXPERIENCE > ? < PRODUCT > # < MANAGEMENT > : < DESIGN > < RESEARCH > / < BRANDING > = < IDENTITY > ; < USER-INTERFACE > ? < DATA > # < CREATIVE > : < FRONT-END > $ < BACK-END > ! < STRATEGY > & < IOS >"
							}
						</span>
					))}
				</div>
				<div className="overlay">
					<div className="window"></div>
				</div>
				<div className="title">
					<h1>We’re SpringHire.</h1>
					<h3>Your technology &amp; brand partner</h3>
				</div>
			</div>
			<div className="home sections-wrapper">
				<Services/>
				<div className="sections-inner">
					<Products/>
				</div>
				<Footer/>
			</div>
		</main>
	);
};
