import React from "react";
import SectionsTemplate from "./SectionsTemplate";
import "./products.scss";

let list = [
	{
		product: "Porsche Motors",
		client: "Porsche",
		services: ["Directing", "Sound Design", "Post Production"],
		imgCount: 2,
	},
	{
		product: "Toro Horizon 360",
		client: "Toro",
		services: ["Branding", "Development", "UX/UI Design"],
		imgCount: 2,
		reversed: true,
	},
	{
		product: "Burst Oral Care",
		client: "Burst Oral",
		services: ["Web Design", "Development", "Branding"],
		imgCount: 1,
	},
	{
		product: "Dance Party",
		services: ["Branding", "Development", "UX/UI Design"],
		imgCount: 3,
		reversed: true,
	},
	{
		product: "Handstand",
		services: ["App Design", "Branding", "Development"],
		imgCount: 3,
	},
	{
		product: "Range",
		services: ["Branding", "Design", "Development", "Quality"],
		imgCount: 1,
		reversed: true,
	},
];

let order = [5, 2, 3, 4, 1, 0];

export default ({ work }) => <>{mapList(work)}</>;

const mapList = (work) => {
	return (
		<>
			{work
				? order.map((e, i) => (
						<SectionsTemplate
							key={i}
							product={list[e].product}
							client={list[e].client}
							services={list[e].services}
							imgCount={list[e].imgCount}
							reversed={list[e].reversed}
							classname={
								list[e].product
									.trim()
									.toLowerCase()
									.split(" ")[0]
							}
						/>
				  ))
				: list.map(
						(
							{ product, client, services, imgCount, reversed },
							i
						) => (
							<SectionsTemplate
								key={i}
								product={product}
								client={client}
								services={services}
								imgCount={imgCount}
								reversed={reversed}
								classname={
									product.trim().toLowerCase().split(" ")[0]
								}
							/>
						)
				  )}
		</>
	);
};
