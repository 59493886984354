import React from "react";

export default () => (
	<section className="team">
		<h3>Our Team</h3>
		<p>
			Our core team is made up of experienced professionals in software
			development, experience design, project management, narrative
			development and marketing. In the last 2 years, we have worked with
			over 80 different clients and continue to grow our teams.
		</p>
		<div className="list">
			<div>
				<div className="name">Kartik Mandaville</div>
				<span className="role">Chief Executive Officer</span>
			</div>
			<div>
				<div className="name">Vinay Agarwal</div>
				<span className="role">Chief Technology Officer</span>
			</div>
			<div>
				<div className="name">Madeline Eckles</div>
				<span className="role">Chief Creative Officer</span>
			</div>
			<div>
				<div className="name">Moshe Vhanunu</div>
				<span className="role">
					Director of Partnership Development
				</span>
			</div>
		</div>
		<h3 className="more">+150 More...</h3>
	</section>
);
