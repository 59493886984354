import React, { useState } from "react";
import Products from "./Components/Products";
import "./styles.scss";
import Footer from "./Components/Footer";
import Nav from "./Components/Nav";
import { NavBtn } from ".";

export default () => {
	const [navOpen, setNavOpen] = useState(false);

	return (
		<main className="work">
			<NavBtn setNavOpen={setNavOpen} navOpen={navOpen} />
			<Nav navOpen={navOpen} />
			<div className="work sections-wrapper">
				<section className="services">
					<h1>Our Work.</h1>
					<h3>
						We build things that matter: brands, products and
						experiences.
					</h3>
				</section>
				<div className="sections-inner">
					<Products work={true} />
				</div>
				<Footer />
			</div>
		</main>
	);
};
