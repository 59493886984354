import React from "react";
import "./App.css";
import Lander from "./components/Lander";
import About from "./components/Lander/About";
import HireUs from "./components/Lander/HireUs";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Work from "./components/Lander/Work";

function App() {
	return (
		<Router>
			<Switch>
				<Route exact path="/">
					<Lander />
				</Route>
				<Route exact path="/about-us">
					<About />
				</Route>
				<Route exact path="/hire-us">
					<HireUs />
				</Route>
				<Route exact path="/work">
					<Work />
				</Route>
			</Switch>
		</Router>
	);
}

export default App;
