import React from "react";

export default ({
					product,
					client,
					services,
					imgCount,
					classname,
					reversed,
				}) => (
	<section className={classname}>
		<h2>{product}</h2>
		<div className={`flex ${reversed ? "reversed" : ""}`}>
			<div className="list">
				<ul>
					<li className="title">Client:</li>
					<li>{client || product}</li>
				</ul>
				<ul>
					<li className="title">Services:</li>
					{services.map((s, i) => (
						<li key={i}>{s}</li>
					))}
				</ul>
			</div>
			<div className="images">
				{[...Array(imgCount)].map((e, i) => (
					<img
						key={i}
						src={(`https://assets-springhire.gumlet.io/${classname}${i + 1}.png?blur=30`)}
						data-src={(`https://assets-springhire.gumlet.io/${classname}${i + 1}.png`)}
						alt={classname}
					/>
				))}
			</div>
		</div>
	</section>
);
