import React from "react";
import Fade from "react-reveal/Fade";
import Icon from "./ImpImg";
import IsMobile from "./IsMobile";
import { Links } from "./Footer";

export default ({ navOpen }) => (
	<Fade duration={500} when={navOpen}>
		<div className={`nav-menu ${!navOpen ? "close" : ""}`}>
			<ul>
				<li className="title">Menu:</li>
				{Links("nav")}
				<li className="social">
					{!IsMobile() && <Icon name="social" />}
				</li>
			</ul>
			<div>
				<ul>
					<li className="title">Contact:</li>
					<li>
						<h5>
							<a href="tel:+13235078708">+1 323-507-8708</a>
						</h5>
					</li>
					<li>
						<h5>
							<a href="mailto:info@springhire.info">
								info@springhire.info
							</a>
						</h5>
					</li>
				</ul>
				<div className="science">
					<span>A Science Inc. Partnership</span>
					<span className="dash" />
					<Icon name="science" />
				</div>
			</div>
			{IsMobile() && <Icon name="social" />}
		</div>
	</Fade>
);
