import React, { useState } from "react";
import { NavBtn } from "./index";
import Nav from "./Components/Nav";
import "./styles.scss";

export default () => {
	const [navOpen, setNavOpen] = useState(false);
	const [formSubmitted, setFormSubmitted] = useState(false);

	return (
		<main className="form">
			<NavBtn setNavOpen={setNavOpen} navOpen={navOpen} />
			<Nav navOpen={navOpen} />
			<div className="hero">
				{formSubmitted ? (
					<>
						<h2>Thank you!</h2>
						<a href="/" className="center">
							Home
						</a>
					</>
				) : (
					<form
						action="mailto:info@springhire.info"
						method="get"
						onSubmit={() => {
							setFormSubmitted(true);
							let f = document.querySelector("form");
							let data = new FormData(f);
							let d = new URLSearchParams(data)
								.toString()
								.replaceAll("+", "%20");
							window.location.href = `mailto:info@springhire.info?${d}`;
						}}
					>
						<h3>Let’s make something...</h3>
						<div>
							<input
								type="text"
								placeholder="Full name"
								name="name"
								required
							/>
						</div>
						<div>
							<input
								type="email"
								placeholder="Email"
								name="from"
								required
							/>
						</div>
						<div>
							<input
								type="text"
								placeholder="Company"
								name="subject"
								required
							/>
						</div>
						<div>
							<textarea
								placeholder="Message"
								name="body"
								rows="5"
								required
							></textarea>
						</div>
						<button>Send message</button>
					</form>
				)}
			</div>
		</main>
	);
};
