import React from "react";
import Icon from "./ImpImg";

export const Links = (where) => {
	return [
		{ text: "About", href: "/about-us" },
		{ text: "Work", href: "/work" },
		{ text: "Hire Us", href: "/hire-us" },
	].map(({ text, href }, i) => {
		let a = <a href={href}>{text}</a>;
		return <li key={i}>{where === "nav" ? <h4>{a}</h4> : <>{a}</>}</li>;
	});
};

export default () => (
	<footer>
		<h6>Ready to build the next big thing together?</h6>
		<a href="/hire-us" className="blue">
			Start a Project
		</a>
		<div className="row">
			<div>
				<a href="/">
					<Icon name="footer-logo" />
				</a>
				<div className="science">
					<span>A Science Inc. Partnership</span>
					<span className="dash" />
					<Icon name="science" />
				</div>
			</div>
			<ul>
				<li>
					<a href="tel:+13235078708">+1 323-507-8708</a>
				</li>
				<li>
					<a href="mailto:info@springhire.info">
						info@springhire.info
					</a>
				</li>
			</ul>
			<ul>{Links()}</ul>
			<Icon name="social" />
		</div>
	</footer>
);
