import React from "react";

export default ({p}) => (
	<section className="services">
		<h3>Our Services</h3>
		{p && (
			<p>
				At SpringHire, we build brands and digital products for some of
				the most innovative startups in the world. A truly one-of-a-kind
				software development and experience design agency—relentlessly
				focused on the end user experience and the ways brands engage,
				communicate and transact with their consumers online and on
				mobile devices. We offer fully integrated product development
				and design services — software development + experience design;
				management, technology consulting, infrastructure design and
				marketing.
			</p>
		)}
		<div className="list">
			<div>
				<img src={'https://assets-springhire.gumlet.io/services/content.svg'}
					 data-src={'https://assets-springhire.gumlet.io/services/content.svg'} alt={''}/>
				<div>Content Strategy</div>
			</div>
			<div>
				<img src={'https://assets-springhire.gumlet.io/services/front-end-dev.svg'}
					 data-src={'https://assets-springhire.gumlet.io/services/front-end-dev.svg'} alt={''}/>
				<div>Front-end Development</div>
			</div>
			<div>
				<img src={'https://assets-springhire.gumlet.io/services/interface.svg'}
					 data-src={'https://assets-springhire.gumlet.io/services/interface.svg'} alt={''}/>
				<div>Interface Design</div>
			</div>
			<div>
				<img src={'https://assets-springhire.gumlet.io/services/back-end-dev.svg'}
					 data-src={'https://assets-springhire.gumlet.io/services/back-end-dev.svg'} alt={''}/>
				<div>Back-end Development</div>
			</div>
		</div>
	</section>
);
