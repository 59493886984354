import React, { useState } from "react";
import Services from "./Components/Services";
import "./styles.scss";
import Footer from "./Components/Footer";
import Nav from "./Components/Nav";
import Team from "./Components/Team";
import { NavBtn } from "./index";
import { Helmet } from "react-helmet";

export default () => {
	const [navOpen, setNavOpen] = useState(false);

	return (
		<main className="about">
			<Helmet>
				<title data-react-helmet="true">About Us | SpringHire</title>
				<meta
					name="description"
					content="Aimed at world class deliverables, SpringHire is a full-service development and design agency. Check our works done and services offered."
					data-react-helmet="true"
				/>
				<meta
					property="og:site_name"
					content="SpringHire"
					data-react-helmet="true"
				/>
				<meta
					property="og:title"
					content="About Us | SpringHire"
					data-react-helmet="true"
				/>
				<meta
					property="og:description"
					content="Aimed at world class deliverables, SpringHire is a full-service development and design agency. Check our works done and services offered."
					data-react-helmet="true"
				/>
				<meta
					property="og:url"
					content="https://www.springhire.info/about-us/"
					data-react-helmet="true"
				/>
				<meta
					property="og:type"
					content="website"
					data-react-helmet="true"
				/>
				<meta
					property="og:image"
					content="https://assets-springhire.gumlet.io/SpringHire- About.jpg"
					data-react-helmet="true"
				/>
				<meta
					property="og:image:secure_url"
					content="https://assets-springhire.gumlet.io/SpringHire- About.jpg"
					data-react-helmet="true"
				/>
				<meta
					property="og:image:width"
					content="1280"
					data-react-helmet="true"
				/>
				<meta
					property="og:image:height"
					content="640"
					data-react-helmet="true"
				/>
				<meta
					property="twitter:title"
					content="About Us | SpringHire"
					data-react-helmet="true"
				/>
				<meta
					property="twitter:description"
					content="Aimed at world class deliverables, SpringHire is a full-service development and design agency. Check our works done and services offered."
					data-react-helmet="true"
				/>
				<meta
					property="twitter:card"
					content="website"
					data-react-helmet="true"
				/>
				<meta
					property="twitter:card"
					content="summary_large_image"
					data-react-helmet="true"
				/>
				<meta
					property="twitter:image"
					content="https://assets-springhire.gumlet.io/SpringHire- About.jpg"
					data-react-helmet="true"
				/>
				<link
					rel="canonical"
					href="https://www.springhire.info/about-us/"
					data-react-helmet="true"
				></link>
			</Helmet>
			<NavBtn setNavOpen={setNavOpen} navOpen={navOpen} />
			<Nav navOpen={navOpen} />
			<div className="sections">
				<section className="banner">
					<div className="top-blur" />
					<div className="center-blur" />
					<div className="title">
						<h1>The future isn’t going to build itself.</h1>
						<p>
							We must be proactive and thoughtful in how we shape
							the most powerful tool ever created - technology.
							SpringHire is a full-service development and design
							agency based in Bangalore and Santa Monica. Our
							processes are both creative and controlled and
							result in world class deliverables.
						</p>
					</div>
				</section>
				<Services p={true} />
				<Team />
				<Footer />
			</div>
		</main>
	);
};
